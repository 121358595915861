import * as React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";
import Div100vh from "react-div-100vh";
import MainWrapper from "src/components/Mainwrapper.js";
import TitleH1 from "src/components/Typography/TitleH1.js";
import Body from "src/components/Typography/Body.js";
import Button from "src/components/Global/Button.js";

const Wrapper = styled(Div100vh)`
  /* height: 100vh; */
  width: 100vw;
  background-color: ${colors.grey};
  position: relative;
  overflow: hidden;
`;

const StyledWrapper = styled(MainWrapper)`
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LeftCol = styled.div`
  width: 41.66%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 15vh;
    margin-bottom: 10vw;
  }
`;

const StyledTitle = styled(TitleH1)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`;

const Description = styled(Body)`
  margin-top: 30rem;
  width: 70%;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ImgWrapper = styled.div`
  width: 50%;
  margin-right: 8.33%;
  position: relative;
  align-self: flex-end;
  height: 50vw;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-right: 0%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    height: inherit;
    width: 100%;
    height: inherit;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    position: absolute;
    bottom: 0;

    height: 40vh;
  }

  @media (max-height: 700px) and (max-width: ${mediaQuery.mobile}) {
  }
`;
const FirstImg = styled.div`
  width: 66.66%;
  position: absolute;

  top: 15vw;
  @media (max-width: ${mediaQuery.desktop}) {
    top: 10vw;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    top: 5vw;
  }
  @media (max-width: ${mediaQuery.mobile}) {
  }
`;
const SecondtImg = styled.div`
  width: 66.66%;
  position: absolute;
  top: 0vw;

  left: 33.33%;
  @media (max-width: ${mediaQuery.desktop}) {
    top: -5vw;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    top: -10vw;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    position: relative;
    top: -15vh;
  }
`;

const Header = ({ title, description, button, firstImg, secondImg }) => {
  return (
    <ParallaxProvider>
      <Wrapper id="top">
        <StyledWrapper>
          <LeftCol>
            <StyledTitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              {noHangers(title)}
            </StyledTitle>
            <Description
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              {noHangers(description)}
            </Description>{" "}
            <Button to="explore" smooth={true} offset={-150} duration={500}>
              {button}
            </Button>
          </LeftCol>

          <ImgWrapper
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            <FirstImg>
              <Parallax speed={20}>
                <Img loading="eager" fluid={firstImg} />
              </Parallax>
            </FirstImg>
            <SecondtImg>
              <Parallax speed={40}>
                <Img loading="eager" fluid={secondImg} />
              </Parallax>
            </SecondtImg>
          </ImgWrapper>
        </StyledWrapper>
      </Wrapper>
    </ParallaxProvider>
  );
};

export default Header;
