import React from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import noHangers from "src/assets/fn/noHangers.js";
import typographySizes from "../../../assets/styles/typographySizes";

const Wrapper = styled.div`
  grid-area: main;
  background-color: ${colors.white};
  overflow: hidden;
  border-radius: 8rem;
  padding: 50rem;
  opacity: 0;
  transform: translateY(20rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 30rem 15rem;
  }
`;

const Size = styled.h3`
  font-size: calc((100vw -${distances.pageMarginBig * 2}) * 0.05);
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: 48rem;
  }
`;

const StyledTitle = styled(Title)`
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.sm}rem;
  }
`;

const StyledBody = styled(Body)`
  margin-top: 10rem;
  max-width: 460rem;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.xs}rem;
  }
`;
const TopWrapper = styled.div`
  /* width: 50%; */
  margin-left: 50%;
  position: relative;
  margin-top: -160rem;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-top: -30rem;
    margin-bottom: 10rem;
  }
  /* min-height: 35vw; */
`;
const ImgWrapper = styled.div`
  top: 0;
  z-index: 2;
  left: 0%;

  height: 35vw;
  @media (max-width: ${mediaQuery.laptop}) {
    height: 55vw !important;
  }

  img {
    position: absolute !important;
    height: 35vw !important;
    @media (max-width: ${mediaQuery.laptop}) {
      height: 55vw !important;
    }
  }
`;

const ImgWrapper2 = styled.div`
  position: absolute !important;
  top: 0;
  z-index: 2;
  left: 45%;
  height: 35vw;
  width: 100%;

  img {
    position: absolute !important;
    height: 35vw !important;
    @media (max-width: ${mediaQuery.laptop}) {
      height: 55vw !important;
    }
  }
  & div {
    overflow: visible !important;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    height: 55vw !important;
  }
`;

const StyledImg = styled(Img)`
  overflow: visible !important;
  & > div {
    padding-bottom: 400% !important;
  }
  img {
    object-position: left bottom !important;
  }
`;

const Main = ({ size, name, description, img1, img2, show }) => (
  <Wrapper className={show && "show"}>
    <TopWrapper>
      <ImgWrapper>
        <StyledImg
          objectPosition="0% 50%"
          fluid={img1.localFile.childImageSharp.fluid}
        />
      </ImgWrapper>
      <ImgWrapper2>
        <StyledImg
          objectPosition="0% 50%"
          fluid={img2.localFile.childImageSharp.fluid}
        />
      </ImgWrapper2>
    </TopWrapper>

    <Size>{size}</Size>
    <StyledTitle>{noHangers(name)}</StyledTitle>
    <StyledBody>{noHangers(description)}</StyledBody>
  </Wrapper>
);

export default Main;
