import React, { useState } from "react";
import styled from "styled-components";
import Main from "src/components/Variant/InfoBox/Main.js";
import SliderImg from "src/components/Variant/InfoBox/SliderImg.js";
import SliderColor from "src/components/Variant/InfoBox/SliderColor.js";
import Info from "src/components/Variant/InfoBox/Info.js";
import ProductList from "src/components/Variant/InfoBox/ProductList.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const Wrapper = styled.div`
  width: 83.33%;
  margin: 0 auto;
  display: none;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  grid-template-columns: calc(60% - 20rem) calc(60% - 20rem) %;
  grid-template-rows: 1fr 0.3fr 1fr auto;
  font-size: 10rem;
  gap: 20rem 20rem;
  grid-template-areas:
    "main  sliderImg"
    "main  sliderColor"
    "main  sliderColor"
    "info  info"
    "productList productList";
  &.show {
    display: grid;
    transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  display: grid;
  @media (max-width: ${mediaQuery.laptop}) {
    grid-template-columns: 100%;
    width: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      "main "
      "sliderImg"
      "sliderColor"
      "info "
      "productList ";
  }
`;

const InfoBox = ({
  show,
  info,
  curStateSliders,
  firstCol,
  secondCol,
  productSpecificationTitle,
  productListTitle,
}) => {
  return (
    <Wrapper>
      <Main
        show={show}
        size={info.Size}
        name={info.Name}
        description={info.Description}
        img1={info.Open_top_binder_v1}
        img2={info.Open_top_binder_v2}
      />
      <SliderImg show={show} slider={info.Top_slider} />
      <SliderColor show={show} colors={info.Top_colors} />
      <Info
        show={show}
        dimensions={info.Dimensions}
        features={info.Product_features}
        additionalInformation={info.Additional_information}
        informationSigns={info.Information_signs}
        firstCol={firstCol}
        secondCol={secondCol}
        productSpecificationTitle={productSpecificationTitle}
      />
      <ProductList
        show={show}
        productList={info.Product_list}
        productListTitle={productListTitle}
      />
    </Wrapper>
  );
};

export default InfoBox;
