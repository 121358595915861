import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ReactPlayer from "react-player";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";

import typographySizes from "src/assets/styles/typographySizes.js";
import Tile from "src/components/Feature/TileMobileSlider.js";
import MainWrapper from "src/components/Mainwrapper.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import Arrow from "src/assets/svg/features-arrow.inline.svg";

const Wrapper = styled.div`
  width: 100vw;

  position: relative;
  overflow: hidden;
  padding: 100rem 0;
  @media (max-width: ${mediaQuery.laptop}) {
    background-color: ${colors.grey};
    padding: 50rem 0;
  }
`;

const StyledWrapper = styled(MainWrapper)`
  display: flex;
  background-color: ${colors.grey};
  padding: 70rem 0;
  border-radius: 8rem;
  justify-content: space-between;

  @media (max-width: ${mediaQuery.desktop}) {
    align-items: center;
  }

  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 0;
  }
`;

const InfoCol = styled.div`
  margin-left: 8.33%;
  width: 33.33%;

  position: relative;
  @media (max-width: ${mediaQuery.desktop}) {
    height: 500rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    height: auto;
    width: 100%;
    margin-left: 0%;
  }
`;

const CardsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 100rem;
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const CardWrapper = styled.div`
  position: absolute;
  padding: 30rem;
  min-height: 230rem;
  cursor: pointer;
  width: 75%;
  background-color: ${colors.white};
  border-radius: 8rem;

  transition: all 0.5s ${(props) => props.index * 0.1}s
    cubic-bezier(0.65, 0, 0.35, 1);

  transform-style: preserve-3d;
  backface-visibility: hidden;
  filter: drop-shadow(rgba(0, 0, 0, 0.08) 0px 0px 20px) opacity(0.99999);

  transform: translate(
    ${(props) => props.index * 25 + props.cur * -25}rem,
    ${(props) => props.index * -25 + props.cur * 25}rem
  );
  z-index: ${(props) => 10 - props.index};

  opacity: 0;
  &.show {
    opacity: 1;
  }
`;

const CardTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.dark};
  margin-bottom: 10rem;
`;

const SwiperWrapper = styled.div`
  width: 100%;
  z-index: 100;
  .swiper {
    overflow: visible !important;
    align-items: stretch;
  }
  .swiper-slide {
    height: initial;
    position: relative;
  }
  display: none;
  @media (max-width: ${mediaQuery.laptop}) {
    display: block;
    margin-top: 40rem;
  }
`;

const AnimationCol = styled.div`
  width: 58.33%;
  position: relative;
  height: auto;

  height: 36.5vw;
  video {
    width: 100%;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 0rem;
    width: calc(100% - 30rem);
    height: 5rem;
    background-color: #dedace;
  }

  @media (max-width: ${mediaQuery.desktop}) {
    width: 50%;
    position: relative;
    height: 34vw;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;

    margin-top: 0rem;
    height: 70vw;
    ::after {
      width: 100%;
      bottom: 0rem;
    }
  }
`;

const StyledArrow = styled(Arrow)``;

const move = keyframes`
  0% {
    transform: translateX(0) ;
  }

  50% {
    transform: translateX(40rem);
  }
  51% {
    transform: translateX(-40rem);
  }
  100% {
    transform: translateX(0);
  }
`;

const moveL = keyframes`
  0% {
    transform: translateX(0) rotate(180deg);
  }

  50% {
    transform: translateX(-40rem) rotate(180deg);
  }
  51% {
    transform: translateX(40rem) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(180deg);
  }
`;

const Next = styled.div`
  width: 55rem;
  height: 55rem;
  border: solid 1rem #dfdfdf;
  border-radius: 55rem;
  background-color: ${colors.white};
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  :hover {
    ${StyledArrow} {
      animation: ${move} 0.7s;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const Prev = styled.div`
  width: 55rem;
  height: 55rem;
  border: solid 1rem #dfdfdf;
  border-radius: 55rem;
  background-color: ${colors.white};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  ${StyledArrow} {
    transform: rotate(180deg);
  }
  :hover {
    ${StyledArrow} {
      animation: ${moveL} 0.7s;
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    display: none;
  }
`;

const InOutWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  /* background-color: blue; */

  display: none;
  &.curent {
    display: block;
  }
`;
const VidWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;

  &.curent {
    display: flex;
  }
  &.out {
    display: none;

    /* filter: hue-rotate(90deg);
    transform: translateX(250rem); */
  }
  &.show {
    display: flex;
  }
  &.showFirst.in {
    display: none !important;
  }
`;

const VidBox = ({
  current,
  vidSrcIn,
  vidSrcOut,
  endAnimFn,
  currentOutPlay,
  readyFn,
  onStart,
}) => {
  let play = current;
  let hideIn = false;
  // useEffect(() => {
  //   play = current;
  // }, [current]);

  return (
    <InOutWrapper id="feature" className={current && "curent"}>
      <VidWrapper className={`${current && "curent"}  `}>
        <ReactPlayer
          height="100%"
          width="100%"
          muted={true}
          playing={play}
          playsinline={true}
          url={[
            {
              src: `${vidSrcIn}`,
              type: "video/mp4",
            },
          ]}
        />
      </VidWrapper>
      <VidWrapper className={`${currentOutPlay && "show"} out `}>
        <ReactPlayer
          height="100%"
          width="100%"
          muted={true}
          playing={currentOutPlay}
          onEnded={endAnimFn}
          onReady={readyFn}
          onStart={onStart(vidSrcOut)}
          playsinline={true}
          url={[
            {
              src: `${vidSrcOut}`,
              type: "video/mp4",
            },
          ]}
        />
      </VidWrapper>
    </InOutWrapper>
  );
};

const Feature = ({ title, posF }) => {
  const [curPos, setCurPos] = useState(0);
  const [currentOut, setCurrentOut] = useState(false);
  const [firstVid, setFirstVid] = useState(true);
  const [changePos, setChangePos] = useState(1);
  const ref = useRef();

  const handleNext = () => {
    setCurrentOut(true);

    if (curPos >= posF.length - 1) {
      setChangePos(0);
    } else {
      setChangePos(curPos + 1);
    }
  };

  const handlePrev = () => {
    setCurrentOut(true);
    if (curPos <= 0) {
      setChangePos(posF.length - 1);
    } else {
      setChangePos(curPos - 1);
    }
  };

  const handleChangeMobile = (index) => {
    setCurrentOut(true);
    setChangePos(index);
  };

  const endAnim = () => {
    setCurPos(changePos);
    setCurrentOut(false);
    // console.log("endanim");
  };

  const readyFn = () => {
    // console.log("ready");
  };

  const onStart = (vidSrcOut) => {
    // console.log(vidSrcOut);
  };

  return (
    <Wrapper>
      <StyledWrapper>
        <InfoCol>
          <Title
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {noHangers(title)}
          </Title>
          <CardsWrapper>
            {posF.map((posData, index) => (
              <CardWrapper
                onClick={() => handleNext()}
                index={index}
                cur={curPos}
                className={index - curPos < 3 && index - curPos > -1 && " show"}
              >
                <CardTitle>{noHangers(posData.Title)}</CardTitle>
                <Body>{noHangers(posData.Description)}</Body>
              </CardWrapper>
            ))}
          </CardsWrapper>
        </InfoCol>
        <AnimationCol ref={ref}>
          <>
            {posF.map((posData, index) => (
              <VidBox
                firstVid={firstVid}
                current={curPos == index}
                currentOutPlay={currentOut && curPos == index}
                vidSrcIn={posData.Animation_in.localFile.publicURL}
                vidSrcOut={posData.Animation_out.localFile.publicURL}
                endAnimFn={endAnim}
                readyFn={readyFn}
                onStart={onStart}
              />
            ))}
          </>
        </AnimationCol>
        <SwiperWrapper>
          <Swiper
            spaceBetween={15}
            slidesPerView={1.2}
            onSlideChange={(swiper) => handleChangeMobile(swiper.activeIndex)}
          >
            {posF.map((posData, index) => (
              <SwiperSlide>
                <Tile
                  num={index + 1}
                  title={posData.Title}
                  description={posData.Description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
        <Next onClick={() => handleNext()}>
          {" "}
          <StyledArrow />{" "}
        </Next>
        <Prev onClick={() => handlePrev()}>
          <StyledArrow />{" "}
        </Prev>
      </StyledWrapper>
    </Wrapper>
  );
};

export default Feature;
