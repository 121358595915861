import React, { useState } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";

import Mainwrapper from "src/components/Mainwrapper.js";
import Title from "src/components/Typography/Title.js";

import Tile from "src/components/Colors/Tile.js";
const Wrapper = styled.div`
  width: 100vw;
  margin-top: 120rem;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;
  padding-bottom: 130rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 60rem;
    padding-bottom: 40rem;
  }
`;

const StyledWrapper = styled(Mainwrapper)`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  text-align: center;
  width: 70%;
  margin-bottom: 40rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const ColorsBullets = styled.div`
  display: flex;
  margin-bottom: 40rem;
`;

const ColorBullet = styled.div`
  width: 18rem;
  height: 18rem;
  background-color: ${(props) => props.color};
  border: solid 1rem
    ${(props) =>
      props.color == "#FFFFFF" || props.color == "#ffffff"
        ? colors.dark
        : "none"};
  margin: 5rem;
  border-radius: 18rem;
  position: relative;
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  cursor: pointer;
  ::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8rem;
    width: 8rem;
    background-color: ${colors.white};
    border: solid 1rem
      ${(props) =>
        props.color == "#FFFFFF" || props.color == "#ffffff"
          ? colors.dark
          : "none"};
    border-radius: 8rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.curent {
    ::after {
      height: 0rem;
      width: 0rem;
    }
  }
  :hover {
    transform: scale(1.15);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const CircleColor = styled.div`
  background-color: ${(props) => props.color};
  width: 80vh;
  height: 80vh;
  position: absolute;
  border-radius: 90vw;
  top: 50%;
  transform: translate(0, -50%);
  z-index: -1;
  clip-path: circle(0% at 50% 50%);
  transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.curent {
    z-index: 0;
    clip-path: circle(50% at 50% 50%);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 55vh;
    height: 55vh;
    border-radius: 55vh;
    transform: translate(-50%, -50%);
    background-color: ${colors.white};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 50vh;
    height: 50vh;
    ::after {
      width: 30vh;
      height: 30vh;
    }
  }
`;

const BinderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 80vh;

  @media (max-width: ${mediaQuery.tablet}) {
    height: 50vh;
    margin-bottom: 40rem;
  }
`;

const BinderImg = styled(Img)`
  position: absolute !important;
  height: 80vh;
  width: 36vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.curent {
    z-index: 100;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 50vh;
    width: 23vh;
  }
`;
const SwiperWrapper = styled.div`
  width: 100%;
  z-index: 100;
  .swiper {
    overflow: visible !important;
    align-items: stretch;
  }
  .swiper-slide {
    height: initial;
    position: relative;
    filter: drop-shadow(0px -1.425px 71.25px rgba(0, 0, 0, 0.08));
    overflow: visible;
  }
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
  }
`;

const TilesDesktop = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const Colors = ({ title, colors, tiles }) => {
  const [curColor, setCurColor] = useState(1);

  const handleClick = (num) => {
    setCurColor(num + 1);
  };
  return (
    <Wrapper>
      <StyledWrapper>
        <StyledTitle
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          {noHangers(title)}
        </StyledTitle>
        <ColorsBullets
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          {colors.map((color, index) => (
            <ColorBullet
              onClick={() => handleClick(index)}
              className={index + 1 === curColor && "curent"}
              color={color.Color_Hex}
            />
          ))}
        </ColorsBullets>

        <BinderWrapper>
          {colors.map((color, index) => (
            <CircleColor
              color={color.Color_Hex}
              className={index + 1 === curColor && "curent"}
            />
          ))}
          {colors.map((color, index) => (
            <BinderImg
              loading="eager"
              objectFit="contain"
              className={index + 1 === curColor && "curent"}
              fluid={color.Binder.localFile.childImageSharp.fluid}
            />
          ))}
          <TilesDesktop>
            <Tile
              num={1}
              title={tiles[0].Title}
              description={tiles[0].Description}
            />
            <Tile
              num={2}
              title={tiles[1].Title}
              description={tiles[1].Description}
            />
            <Tile
              num={3}
              title={tiles[2].Title}
              description={tiles[2].Description}
            />
          </TilesDesktop>
        </BinderWrapper>
        <SwiperWrapper>
          <Swiper spaceBetween={15} slidesPerView={1.2}>
            <SwiperSlide>
              {" "}
              <Tile
                num={1}
                title={tiles[0].Title}
                description={tiles[0].Description}
              />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <Tile
                num={2}
                title={tiles[1].Title}
                description={tiles[1].Description}
              />
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <Tile
                num={3}
                title={tiles[2].Title}
                description={tiles[2].Description}
              />
            </SwiperSlide>
          </Swiper>
        </SwiperWrapper>
      </StyledWrapper>
    </Wrapper>
  );
};

export default Colors;
