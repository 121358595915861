import React, { useRef, useEffect, useState } from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import noHangers from "src/assets/fn/noHangers.js";

import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ArrowSlider from "src/components/Variant/InfoBox/ArrowSlider.js";

const Wrapper = styled.div`
  grid-area: info;
  background-color: ${colors.white};
  overflow: hidden;
  border-radius: 8rem;
  /* height: 200rem; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  opacity: 0;
  transform: translateY(20rem);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.8s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;
const OpenCloseWrapper = styled.div`
  /* background-color: red; */
  height: 20rem;
  width: 20rem;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .bar {
    width: 100%;
    height: 2rem;
    display: inline-block;
    background-color: ${colors.dark};
    position: absolute;
    top: 50%;
  }
  .bar:first-of-type {
    transform: rotate(90deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  .bar:last-of-type {
  }
  &.open {
    .bar:first-of-type {
      transform: rotate(0deg);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;
const TopTab = styled.div`
  padding: 38rem 50rem;
  border-bottom: 1rem solid #e8e8e8;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  :hover {
    ${OpenCloseWrapper} {
      transform: rotate(180deg);
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
`;

const BottomTab = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const TabTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  font-weight: 300;
`;

const TopWrapper = styled.div`
  border-bottom: 1rem solid #e8e8e8;
  display: flex;
  flex-wrap: wrap;
`;

const SectionTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  margin-bottom: 20rem;
  font-weight: 300;
`;

const SizesWrapper = styled.div`
  width: calc(50% - 2rem);
  border-right: 1rem solid #e8e8e8;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    border-right: none;
    border-bottom: 1rem solid #e8e8e8;
  }
`;

const SizesInnerWrapper = styled.div`
  padding: 50rem;
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
`;

const DimensionsWrapper = styled.div``;

const DimensionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 7rem 0;
  @media (max-width: ${mediaQuery.laptop}) {
    justify-content: flex-start;
  }
`;

const PosName = styled.span`
  font-size: ${typographySizes.s}rem;
  @media (max-width: ${mediaQuery.laptop}) {
    margin-right: 30rem;
  }
`;

const PosDimensions = styled.span`
  font-size: ${typographySizes.s}rem;
  font-weight: 500;
`;

const FeaturesWrapper = styled.div`
  width: 50%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const FeaturesInnerWrapper = styled.div`
  padding: 50rem;
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
`;

const FeaturesPosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FeaturesPosCol = styled.div`
  margin-right: 40rem;
  display: flex;
  margin-bottom: 20rem;
  flex-direction: column;
`;

const Feature = styled.span`
  display: inline-block;
  /* padding: 0 20rem; */
  /* min-width: 100rem; */
  text-align: left;
  /* border: solid ${colors.dark} 1rem; */
  height: 34rem;
  line-height: 34rem;
  /* border-radius: 34rem; */
  margin-top: 15rem;
  font-size: ${typographySizes.xs}rem;
`;

const AdditionalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(60% - 100rem);
  padding: 50rem;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 30rem 15rem;
  }
`;

const AdditionalPos = styled.p`
  width: calc(50% - 50rem);
  padding-left: 20rem;
  font-size: ${typographySizes.xs}rem;
  position: relative;
  line-height: 1.4;
  margin-bottom: 20rem;
  margin-right: 20rem;
  :nth-last-of-type(2),
  :nth-last-of-type(1) {
    margin-bottom: 0;
  }
  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 11rem;
    width: 11rem;
    border-radius: 11rem;
    background-color: #ed5f5b;
  }
  ::after {
    content: "";
    position: absolute;
    left: 3rem;
    top: 3rem;
    height: 5rem;
    width: 5rem;
    border-radius: 11rem;
    background-color: #fff;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% - 20rem);
    padding-left: 20rem;
    margin-bottom: 20rem;
    :nth-last-of-type(1) {
      margin-bottom: 0;
    }
    :nth-last-of-type(2) {
      margin-bottom: 20rem;
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SignsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 35rem 50rem 15rem 50rem;
  width: calc(40% - 100rem);
  flex-direction: row-reverse;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    border-top: solid #e8e8e8 1rem;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 50rem 15rem 50rem;
  }
`;

const SignImg = styled(Img)`
  width: 53rem;
  margin-left: 25rem;
  margin-bottom: 20rem;
  img {
    object-fit: contain !important;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    margin-right: 15rem;
    margin-left: 15rem;
  }
`;

const Info = ({
  dimensions,
  features,
  additionalInformation,
  informationSigns,
  show,
  firstCol,
  secondCol,
  productSpecificationTitle,
}) => {
  const box = useRef(null);
  const [boxH, setBoxH] = useState(0);
  const [curentBoxH, setCurentboxH] = useState(null);
  const [openBox, setOpenBox] = useState(true);

  useEffect(() => {
    const height = box.current.offsetHeight;

    setBoxH(height);

    setTimeout(function () {
      setCurentboxH(0);
    }, 100);
  }, [box]);

  const mapClick = () => {
    setOpenBox(openBox ? false : true);

    openBox ? setCurentboxH(boxH) : setCurentboxH(0);
  };
  return (
    <Wrapper className={show && "show"}>
      <TopTab onClick={mapClick}>
        <TabTitle>{productSpecificationTitle}</TabTitle>
        <OpenCloseWrapper className={!openBox && "open"}>
          <span className="bar" />
          <span className="bar" />
        </OpenCloseWrapper>
      </TopTab>
      <BottomTab
        ref={box}
        style={{
          height: curentBoxH + "px",
        }}
      >
        <TopWrapper>
          <SizesWrapper>
            <SizesInnerWrapper>
              <SectionTitle>{noHangers(firstCol)}</SectionTitle>
              <DimensionsWrapper>
                {dimensions.map((pos) => (
                  <DimensionsRow>
                    <PosName>{noHangers(pos.Name)}</PosName>
                    <PosDimensions>{noHangers(pos.Details)}</PosDimensions>
                  </DimensionsRow>
                ))}
              </DimensionsWrapper>
            </SizesInnerWrapper>
          </SizesWrapper>
          <FeaturesWrapper>
            <FeaturesInnerWrapper>
              <SectionTitle>{noHangers(secondCol)}</SectionTitle>
              <DimensionsWrapper>
                {features.map((pos) => (
                  <DimensionsRow>
                    <PosName>{noHangers(pos.Name)}</PosName>
                    <PosDimensions>{noHangers(pos.Details)}</PosDimensions>
                  </DimensionsRow>
                ))}
              </DimensionsWrapper>
            </FeaturesInnerWrapper>
          </FeaturesWrapper>
        </TopWrapper>
        <BottomWrapper>
          <AdditionalWrapper>
            {additionalInformation.map((pos) => (
              <AdditionalPos>{noHangers(pos.Text)}</AdditionalPos>
            ))}
          </AdditionalWrapper>
          <SignsWrapper>
            {informationSigns.map((pos) => (
              <SignImg fluid={pos.Img.localFile.childImageSharp.fluid} />
            ))}
          </SignsWrapper>
        </BottomWrapper>
      </BottomTab>
    </Wrapper>
  );
};

export default Info;
