import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ArrowSlider from "src/components/Variant/InfoBox/ArrowSlider.js";

const Wrapper = styled.div`
  grid-area: sliderColor;
  background-color: ${colors.white};
  overflow: hidden;
  border-radius: 8rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  @media (max-width: ${mediaQuery.laptop}) {
    height: 80vw;
  }
  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  opacity: 0;
  transform: translateY(20rem);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.7s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const BindersWrapper = styled.div`
  width: 100%;
  margin-top: 20rem;
  height: calc(100% - 120rem);
  position: relative;
`;
const BinderImg = styled(Img)`
  position: absolute !important;
  left: 50%;
  width: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  img {
    object-fit: contain !important;
  }
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.curent {
    z-index: 100;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const NavWrapper = styled.div`
  position: absolute;
  bottom: 20rem;
  left: 20rem;
  width: calc(100% - 40rem);
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

const ColorsBulletsWrapper = styled.div`
  width: calc(100% - 120rem);
  overflow: hidden;
  position: relative;
  ::after {
    pointer-events: none;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20rem;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  ::before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20rem;
    z-index: 100;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  @media (min-width: 1500px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
const ColorsBullets = styled.div`
  display: flex;

  width: calc(100% - 40rem);
  margin-left: 20rem;
  transform: translate(calc(50% - 16rem));
  position: relative;
  @media (min-width: 1500px) {
    transform: translate(0);

    width: auto;
    margin-left: 0rem;
  }
`;

const ColorBullet = styled.div`
  width: 18rem;
  height: 18rem;

  background-color: ${(props) => props.color};
  margin: 5rem;
  border-radius: 18rem;
  position: relative;
  flex-shrink: 0;
  /* transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1); */
  border: solid 1rem
    ${(props) =>
      props.color == "#FFFFFF " || props.color == "#ffffff"
        ? colors.dark
        : "none"};
  cursor: pointer;
  ::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8rem;
    width: 8rem;
    background-color: ${colors.white};
    border: solid 1rem
      ${(props) =>
        props.color == "#FFFFFF " || props.color == "#ffffff"
          ? colors.dark
          : "none"};
    border-radius: 8rem;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.curent {
    ::after {
      height: 0rem;
      width: 0rem;
    }
  }
  :hover {
    transform: scale(1.15);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (min-width: 1500px) {
    &.hide {
      display: none;
    }
    transform: translateX(0) !important;
  }
`;

const ColorName = styled(Body)`
  text-align: center;
  width: 100%;
  margin-top: 5rem;
`;

const SliderColor = ({ colors, show }) => {
  const [cur, setCur] = useState(0);

  const handleClickLeft = (num) => {
    if (cur <= 0) {
      setCur(colors.length - 1);
    } else {
      setCur(cur - num);
    }
  };
  const handleClickRight = (num) => {
    if (cur >= colors.length - 1) {
      setCur(0);
    } else {
      setCur(cur + num);
    }
  };
  const handleClick = (num) => {
    setCur(num);
  };
  useEffect(() => {
    setCur(0);
  }, [colors]);
  return (
    <Wrapper className={show && "show"}>
      <BindersWrapper>
        {colors.map((color, index) => (
          <BinderImg
            loading="eager"
            objectFit="contain"
            className={index === cur && "curent"}
            fluid={color.Binder.localFile.childImageSharp.fluid}
          />
        ))}
      </BindersWrapper>
      <NavWrapper>
        <ArrowSlider fnClick={handleClickLeft} left />
        <ColorsBulletsWrapper>
          <ColorsBullets>
            {colors.map((color, index) => (
              <ColorBullet
                style={{
                  transform: `translateX(calc(${cur * -28}rem + ${
                    colors.length * -28
                  }rem)`,
                }}
                onClick={() => handleClick(index)}
                className={index === cur ? "curent hide" : "hide"}
                color={color.Color_Hex}
              />
            ))}
            {colors.map((color, index) => (
              <ColorBullet
                style={{
                  transform: `translateX(calc(${cur * -28}rem + ${
                    colors.length * -28
                  }rem)`,
                }}
                onClick={() => handleClick(index)}
                className={index === cur && "curent"}
                color={color.Color_Hex}
              />
            ))}
            {colors.map((color, index) => (
              <ColorBullet
                style={{
                  transform: `translateX(calc(${cur * -28}rem + ${
                    colors.length * -28
                  }rem)`,
                }}
                onClick={() => handleClick(index)}
                className={index === cur ? "curent hide" : "hide"}
                color={color.Color_Hex}
              />
            ))}
          </ColorsBullets>
          <ColorName>{colors[cur] && colors[cur].Color_name}</ColorName>
        </ColorsBulletsWrapper>
        <ArrowSlider fnClick={handleClickRight} />
      </NavWrapper>
    </Wrapper>
  );
};

export default SliderColor;
