import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import gsap from "gsap";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const ImgFirstCol = styled.div`
  width: calc(50% - 10rem);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 195rem;
    div {
      height: 100%;
    }
  }
`;

const StyledImg = styled(Img)`
  margin-bottom: 20rem;
  border-radius: 8rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: ${(props) => props.ratio * 195}rem;
    margin-bottom: 0rem;
    margin-right: 15rem;
  }
`;

const ImgWrap = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    position: relative;
  }
`;
const ImgWrapBlok = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    position: relative;
  }
`;

const ImgsStripe = ({ imgs, sec }) => {
  const wrapper = useRef(null);
  let intViewportWidth = 0;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  useEffect(() => {
    const movmentTimeline = gsap.timeline({
      repeat: -1,
    });
    const element = wrapper.current;
    const bounds = element.getBoundingClientRect();
    const speed = sec ? 0.006 : 0.008;
    if (isBrowser && intViewportWidth > 768) {
      const h = bounds.height;
      movmentTimeline
        .set(element, { y: 0 })
        .to(element, { y: -h / 2, duration: h * speed, ease: "linear" });
    } else {
      const boundsIn = element.children[0].getBoundingClientRect();
      const w = boundsIn.width;

      movmentTimeline
        .set(element, { x: 0 })
        .to(element, { x: -w, duration: w * 2 * speed, ease: "linear" });
    }
  });
  return (
    <ImgFirstCol>
      <ImgWrap ref={wrapper}>
        <ImgWrapBlok>
          {imgs.map((posImg) => (
            <>
              <StyledImg
                critical={true}
                ratio={posImg.localFile.childImageSharp.fluid.aspectRatio}
                fluid={posImg.localFile.childImageSharp.fluid}
              />
            </>
          ))}{" "}
        </ImgWrapBlok>
        <ImgWrapBlok>
          {imgs.map((posImg) => (
            <StyledImg
              critical={true}
              ratio={posImg.localFile.childImageSharp.fluid.aspectRatio}
              fluid={posImg.localFile.childImageSharp.fluid}
            />
          ))}{" "}
        </ImgWrapBlok>
      </ImgWrap>
    </ImgFirstCol>
  );
};

export default ImgsStripe;
