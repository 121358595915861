import React, { useRef, useEffect, useState } from "react";
import Img from "gatsby-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import styled from "styled-components";
import colors from "src/assets/styles/colors.js";
import typographySizes from "src/assets/styles/typographySizes.js";

import distances from "src/assets/styles/distances.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ArrowSlider from "src/components/Variant/InfoBox/ArrowSlider.js";

const Wrapper = styled.div`
  grid-area: productList;
  background-color: #363636;
  overflow: hidden;
  border-radius: 8rem;
  z-index: 999;

  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));

  opacity: 0;
  transform: translateY(20rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.5s 0.9s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const OpenCloseWrapper = styled.div`
  height: 20rem;
  width: 20rem;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .bar {
    width: 100%;
    height: 2rem;
    display: inline-block;
    background-color: ${colors.white};
    position: absolute;
    top: 50%;
  }
  .bar:first-of-type {
    transform: rotate(90deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  .bar:last-of-type {
  }
  &.open {
    .bar:first-of-type {
      transform: rotate(0deg);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;
const TopTab = styled.div`
  padding: 38rem 50rem;
  border-bottom: 1rem solid #e8e8e8;

  cursor: pointer;
  display: flex;
  justify-content: space-between;
  :hover {
    ${OpenCloseWrapper} {
      transform: rotate(180deg);
    }
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
`;

const BottomTab = styled.div`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const SectionTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;

  font-weight: 300;
  color: ${colors.white};
`;

const ListWrapper = styled.ul`
  width: 70%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 50rem;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 35rem 15rem;
  }
`;
const Pos = styled.li`
  width: calc(50% - 50rem);
  padding-left: 20rem;
  color: ${colors.white};
  margin-right: 20rem;
  font-size: ${typographySizes.s}rem;
  position: relative;
  line-height: 1.4;
  margin-bottom: 10rem;
  :nth-last-of-type(2),
  :nth-last-of-type(1) {
    margin-bottom: 0;
  }
  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4rem;
    height: 11rem;
    width: 11rem;
    border-radius: 11rem;
    background-color: ${(props) => props.color};
    border: solid 1rem
      ${(props) =>
        props.color == "#000000" || props.color == "#323232"
          ? colors.white
          : "none"};
  }
  ::after {
    content: "";
    position: absolute;
    left: 3rem;
    top: 7rem;
    height: 5rem;
    width: 5rem;
    border-radius: 11rem;
    background-color: #363636;
    border: solid 1rem
      ${(props) =>
        props.color == "#000000" || props.color == "#323232"
          ? colors.white
          : "none"};
  }
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(100% - 30rem);
    margin-right: 0rem;
    :nth-last-of-type(2) {
      margin-bottom: 10rem;
    }
  }
`;

const ProductList = ({ productList, show, productListTitle }) => {
  const box = useRef(null);
  const [boxH, setBoxH] = useState(0);
  const [curentBoxH, setCurentboxH] = useState(null);
  const [openBox, setOpenBox] = useState(true);

  useEffect(() => {
    const height = box.current.offsetHeight;

    setBoxH(height);

    setTimeout(function () {
      setCurentboxH(0);
    }, 100);
  }, [box]);

  const mapClick = () => {
    setOpenBox(openBox ? false : true);

    openBox ? setCurentboxH(boxH) : setCurentboxH(0);
  };
  return (
    <Wrapper className={show && "show"}>
      <TopTab onClick={mapClick}>
        <SectionTitle>{productListTitle}</SectionTitle>
        <OpenCloseWrapper className={!openBox && "open"}>
          <span className="bar" />
          <span className="bar" />
        </OpenCloseWrapper>
      </TopTab>
      <BottomTab
        ref={box}
        style={{
          height: curentBoxH + "px",
        }}
      >
        <ListWrapper>
          {productList.map((pos) => (
            <Pos color={pos.Color_hex}>{pos.Name}</Pos>
          ))}
        </ListWrapper>
      </BottomTab>
    </Wrapper>
  );
};

export default ProductList;
