import React, { useState, useEffect } from "react";
import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Img from "gatsby-image";
import Tilt from "react-parallax-tilt";
import Title from "src/components/Typography/Title.js";
import distances from "src/assets/styles/distances.js";
import ArrowButton from "src/components/Variant/ArrowButtonThumb.js";

const WrapperIn = styled.div`
  width: calc(100% - 60rem);
  height: calc(100% - 60rem);
  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));

  background-color: ${colors.white};
  border-radius: 8rem;
  padding: 30rem;
`;

const ImgWrapper = styled.div`
  margin-bottom: 40rem;

  position: relative;
  height: 20vw;
  @media (max-width: ${mediaQuery.laptop}) {
    height: 40vw;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
  img {
    object-fit: contain !important;
  }
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`;

const Size = styled.h3`
  font-size: 6vw;
  font-size: calc((100vw - ${distances.pageMarginBig * 2}) * 0.05);
  font-weight: 500;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: 48rem;
  }
`;

const Type = styled.span`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.dark};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;

  :hover {
    ${StyledImg} {
      transform: scale(1.05);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const VariatnThumbnail = ({ variant }) => {
  const [hover, setHover] = useState(false);
  const info = variant.node;

  let intViewportWidth = 0;
  const [mobile, setMobile] = useState(false);
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }

  useEffect(() => {
    if (isBrowser) {
      if (isBrowser && intViewportWidth < 1024) {
        setMobile(true);
      }
    }
  }, []);
  return (
    <Wrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tilt
        tiltMaxAngleX="10"
        tiltMaxAngleY="10"
        reset={true}
        tiltReverse={true}
        tiltEnable={!mobile}
      >
        <WrapperIn>
          <ImgWrapper>
            <StyledImg
              objectFit="contain"
              fluid={info.Thumbnail_img.localFile.childImageSharp.fluid}
            />
          </ImgWrapper>

          <Row>
            <Size>{info.Size}</Size>
            <ArrowButton hover={hover} />
          </Row>

          <Type>{info.Type}</Type>
        </WrapperIn>
      </Tilt>
    </Wrapper>
  );
};

export default VariatnThumbnail;
