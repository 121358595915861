import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Tilt from "react-parallax-tilt";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import useIsInViewport from "src/assets/fn/useIsInViewport.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import noHangers from "src/assets/fn/noHangers.js";

import MainWrapper from "src/components/Mainwrapper.js";
import Title from "src/components/Typography/Title.js";
import Body from "src/components/Typography/Body.js";
import ReactPlayer from "react-player";

const StyledWrapper = styled(MainWrapper)`
  margin-top: 120rem;
  margin-bottom: 120rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mediaQuery.laptop}) {
    flex-direction: column;
    margin-top: 40rem;
    margin-bottom: 60rem;
  }
`;
const StyledTitle = styled(Title)`
  margin-bottom: 20rem;
`;
const InfoCol = styled.div`
  width: 33.33%;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-bottom: 40rem;
  }
`;

const TilesColWrapper = styled.div`
  display: flex;
  width: 58.33%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const TilesCol = styled.div`
  width: calc(50% - 10rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
`;

const TileVid = styled.div`
  margin-bottom: 20rem;
  width: 100%;
  /* height: calc((100vw - ${distances.pageMarginBig * 2}rem) * 0.179); */
  :last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    /* height: calc((100vw - ${distances.pageMarginBig}rem) * 0.7); */
    margin-bottom: 15rem;
    :last-of-type {
      margin-bottom: 15rem;
    }
  }
`;

const TileVidIn = styled.div`
  border-radius: 8rem;
  overflow: hidden;
  position: relative;
  filter: drop-shadow(0px -1.425px 71.25px rgba(0, 0, 0, 0.08));
  .newVid {
    left: 0;
    height: calc((100vw - ${distances.pageMarginBig * 2}rem) * 0.179);
    /* width: 100rem; */
    /* width: 177.77777778vh; 
  min-width: 100%;
  min-height: 56.25vw; */

    /* top: calc(50% - 1rem); */
    /* transform: translate(-50%, -50%);  */
    z-index: 1;
    @media (max-width: ${mediaQuery.laptop}) {
      height: 100%;
      width: 100%;
    }
  }
`;

const TileTitle = styled.h2`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.dark};
  position: absolute;
  bottom: 20rem;
  left: 20rem;
  width: 150rem;
  z-index: 50;
  &.white {
    color: ${colors.white};
  }
  &.w100 {
    width: 100%;
  }
  &.right {
    left: initial;
    right: 20rem;
    text-align: right;
  }
`;

const NumWrapper = styled.div`
  display: flex;
  margin-bottom: 20rem;
  justify-content: space-between;
`;

const TileNum = styled.div`
  width: calc(50% - 10rem);
  @media (max-width: ${mediaQuery.laptop}) {
    width: calc(50% - 7.5rem);
  }
`;

const TileNumIn = styled.div`
  position: relative;
  filter: drop-shadow(0px -1.425px 71.25px rgba(0, 0, 0, 0.08));
  padding: 20rem;
  background-color: ${colors.white};
  border-radius: 8rem;
`;

const Num = styled.span`
  font-size: 7vw;
  width: 100%;
  font-weight: 500;
  text-align: center;
  display: block;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: 14vw;
  }
`;

const TileTitleNum = styled.h2`
  font-size: ${typographySizes.sm}rem;
  color: ${colors.dark};
`;

const FeaturesShort = ({ title, description, tileVideo, tileNum }) => {
  const ref1 = useRef(null);
  let isInViewport1 = useIsInViewport(ref1);
  const ref2 = useRef(null);
  let isInViewport2 = useIsInViewport(ref2);
  const ref3 = useRef(null);
  let isInViewport3 = useIsInViewport(ref3);
  const ref4 = useRef(null);
  let isInViewport4 = useIsInViewport(ref4);
  let intViewportWidth = 0;

  const [mobile, setMobile] = useState(false);

  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }

  useEffect(() => {
    if (isBrowser) {
      if (isBrowser && intViewportWidth < 1024) {
        setMobile(true);
      }
    }
  }, []);

  // const endAnim = () => {
  //   setCurrentOut(false);
  //   setCurPos(changePos);
  // };

  return (
    <ParallaxProvider>
      <StyledWrapper id="explore">
        <InfoCol>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {noHangers(title)}
          </StyledTitle>

          <Body
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {noHangers(description)}
          </Body>
        </InfoCol>
        <TilesColWrapper>
          <TilesCol>
            <Parallax speed={mobile ? 0 : 10}>
              <TileVid ref={ref1}>
                <Tilt
                  tiltMaxAngleX="10"
                  tiltMaxAngleY="10"
                  reset={true}
                  tiltReverse={true}
                  tiltEnable={!mobile}
                >
                  <TileVidIn>
                    <video className="newVid" autoPlay muted playsInline>
                      <source
                        src={tileVideo[0].Video.localFile.publicURL}
                        type="video/mp4"
                      />
                    </video>

                    <TileTitle>{noHangers(tileVideo[0].Text)}</TileTitle>
                  </TileVidIn>
                </Tilt>
              </TileVid>
              <TileVid ref={ref2}>
                <Tilt
                  tiltMaxAngleX="10"
                  tiltMaxAngleY="10"
                  reset={true}
                  tiltReverse={true}
                  tiltEnable={!mobile}
                >
                  <TileVidIn>
                    <video className="newVid" autoPlay muted playsInline>
                      <source
                        src={tileVideo[1].Video.localFile.publicURL}
                        type="video/mp4"
                      />
                    </video>
                    <TileTitle className="w100 white">
                      {noHangers(tileVideo[1].Text)}
                    </TileTitle>
                  </TileVidIn>
                </Tilt>
              </TileVid>
            </Parallax>
          </TilesCol>

          <TilesCol>
            <Parallax speed={mobile ? 0 : -10}>
              <TileVid ref={ref3}>
                <Tilt
                  tiltMaxAngleX="10"
                  tiltMaxAngleY="10"
                  reset={true}
                  tiltReverse={true}
                  tiltEnable={!mobile}
                >
                  <TileVidIn>
                    <video className="newVid" autoPlay loop muted playsInline>
                      <source
                        src={tileVideo[2].Video.localFile.publicURL}
                        type="video/mp4"
                      />
                    </video>

                    <TileTitle>{tileVideo[2].Text}</TileTitle>
                  </TileVidIn>
                </Tilt>
              </TileVid>
              <NumWrapper>
                <TileNum>
                  <Tilt
                    tiltMaxAngleX="10"
                    tiltMaxAngleY="10"
                    reset={true}
                    tiltReverse={true}
                    tiltEnable={!mobile}
                  >
                    <TileNumIn>
                      <Num>{tileNum[0].Number}</Num>
                      <TileTitleNum>{tileNum[0].Text}</TileTitleNum>
                    </TileNumIn>
                  </Tilt>
                </TileNum>
                <TileNum>
                  <Tilt
                    tiltMaxAngleX="10"
                    tiltMaxAngleY="10"
                    reset={true}
                    tiltReverse={true}
                    tiltEnable={!mobile}
                  >
                    <TileNumIn>
                      <Num>{tileNum[1].Number}</Num>
                      <TileTitleNum>{tileNum[1].Text}</TileTitleNum>
                    </TileNumIn>
                  </Tilt>
                </TileNum>
              </NumWrapper>
              <TileVid ref={ref4}>
                <Tilt
                  tiltMaxAngleX="10"
                  tiltMaxAngleY="10"
                  reset={true}
                  tiltReverse={true}
                  tiltEnable={!mobile}
                >
                  <TileVidIn>
                    <video className="newVid" autoPlay muted playsInline>
                      <source
                        src={tileVideo[3].Video.localFile.publicURL}
                        type="video/mp4"
                      />
                    </video>

                    <TileTitle className="right w100">
                      {noHangers(tileVideo[3].Text)}
                    </TileTitle>
                  </TileVidIn>
                </Tilt>
              </TileVid>
            </Parallax>
          </TilesCol>
        </TilesColWrapper>
      </StyledWrapper>
    </ParallaxProvider>
  );
};

export default FeaturesShort;
